<template>

  <b-card>
    <b-table
      ref="selectableTable"
      selectable
      select-mode="single"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      primary-key="Monat"
      :fields="fields"
      @row-selected="showMonatTable"
    >
      <template #row-details>
        <div class="d-flex align-items-center justify-content-end mb-1">
          <b-button
            v-show="currRow.Jahr > 0"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="generateAll"
          >
            <feather-icon
              icon="PlusSquareIcon"
              class="mr-50"
            />
            <span class="align-middle">Rechnungen generieren</span>
          </b-button>
        </div>

        <b-table
          ref="selectableTableX"
          selectable
          select-mode="single"
          striped
          hover
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="itemsMonat"
          primary-key="MandantID"
          :fields="dosFields"
          @row-selected="showAbrech"
        >
          <template #cell(Status)="data">
            <b-badge :variant="statusRow[1][data.value]">
              {{ statusRow[0][data.value] }}
            </b-badge>
          </template>
          <template #row-details="row">
            <b-card>
              <b-row>
                <b-col md="3">
                  <b-form-group
                    label="Erstellt"
                  >
                    {{ currRowAbrech.Erstellt }}
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label="Versand"
                  >
                    {{ currRowAbrech.Versand }}
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label="Erinnert"
                  >
                    {{ currRowAbrech.Erinnert }}
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label="Gemahnt"
                  >
                    {{ currRowAbrech.Gemahnt }}
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <b-form-group
                    label="Status / Aktion"
                    label-for="Status"
                  >
                    <v-select
                      v-model="row.item.Status"
                      :options="status"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3 text-center mt-2">
                  <b-button
                    variant="primary"
                    :disabled="currRowAbrech.AbrechnungID !== null"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="create"
                  >
                    Erstellen
                  </b-button>
                </b-col>
                <b-col md="2 text-center mt-2">
                  <b-button
                    variant="primary"
                    :disabled="currRowAbrech.Erstellt === null"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="send"
                  >
                    Versenden
                  </b-button>
                </b-col>
                <b-col md="2 text-right mt-2">
                  <b-button
                    variant="primary"
                    :disabled="currRowAbrech.AbrechnungID === null"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="download"
                  >
                    Herunterladen
                  </b-button>
                </b-col>
                <b-col md="2 text-right mt-2">
                  <b-button
                    variant="primary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    :disabled="! (file === null || (file !== null && file.size <= maxFileSize))"
                    @click="speichern(row.item)"
                  >
                    Speichern
                  </b-button>
                </b-col>
              </b-row>
              <!-- <b-row>
                <b-col md="9">
                  <b-form-group
                    label="Bemerkung"
                    label-for="Bemerkung"
                  >
                    <b-form-textarea
                      v-model="row.item.Bemerkung"
                      rows="2"
                      max-rows="3"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3 text-right mt-4">
                  <b-button
                    variant="primary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    :disabled="! (file === null || (file !== null && file.size <= maxFileSize))"
                    @click="speichern(row.item)"
                  >
                    Speichern
                  </b-button>
                </b-col>
              </b-row> -->
            </b-card>
          </template>
        </b-table>

      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Pro Seite"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

  </b-card>
</template>

<script>
import {
  BButton, BBadge, BCard, BTable, BRow, BCol, BFormGroup, BFormSelect, BPagination, BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import dba from '@/service/abrechnung'

export default {
  components: {
    BButton,
    BBadge,
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    vSelect,
    // BFormTextarea,
    // BFormFile,
    BPagination,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  setup() {
    const status = [
      { label: 'Erstellt', value: 'erstellt' },
      { label: 'Versandt', value: 'versand' },
      { label: 'Bezahlt', value: 'bezahlt' },
      { label: 'Erinnert', value: 'erinnert' },
      { label: 'Gemahnt', value: 'gemahnt' },
    ]
    return {
      status,
    }
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [3, 5, 10, 15],
      totalRows: 1,
      currentPage: 1,
      currRow: null,
      currRowAbrech: null,
      file: null,
      maxFileSize: 500000,
      fields: [
        { key: 'Jahr', label: 'Jahr' },
        { key: 'Monat', label: 'Monat' },
        { key: 'PreisTrennung', label: 'Trennung' },
        { key: 'PreisScheidung', label: 'Scheidung' },
        { key: 'PreisBetreuung', label: 'Betreuung' },
        { key: 'PreisPartnerschaft', label: 'Partnerschaft' },
        { key: 'PreisKind', label: 'Kind' },
        { key: 'PreisGA', label: 'GA' },
        { key: 'PreisErweiterungen', label: 'Erw' },
      ],
      dosFields: [
        { key: 'Firma', label: 'Mandant' },
        { key: 'Status', label: 'Status' },
        { key: 'PreisTrennung', label: 'Trennungen' },
        { key: 'PreisScheidung', label: 'Scheidungen' },
        { key: 'PreisBetreuung', label: 'Betreuung' },
        { key: 'PreisPartnerschaft', label: 'Partnerschaft' },
        { key: 'Total', label: 'Total' },
      ],
      /* eslint-disable global-require */
      items: null,
      itemsMonat: null,
      tblRow: null,
      selected: [],
      anzahlBelegeTotal: 0,
      statusRow: [{
        erstellt: 'Erstellt', versand: 'Versandt', bezahlt: 'Bezahlt', erinnert: 'Zahlungserinnerung', gemahnt: 'Mahnung',
      },
      {
        erstellt: '', versand: '', bezahlt: 'light-success', erinnert: 'light-warning', gemahnt: 'light-warning',
      }],
    }
  },
  async created() {
    const r = await dba.getAbrechnungAdmin()
    if (r.data.length > 0) {
      for (let i = 0; i < r.data.length; i += 1) r.data[i]._showDetails = false // eslint-disable-line
      this.items = r.data
      this.totalRows = this.items.length
    }
  },
  methods: {
    async showMonatTable(row) {
      if (row.length === 1) {
        if (this.currRow !== null && this.currRow.Monat !== row[0].Monat) this.currRow._showDetails = false // eslint-disable-line
        const r = await dba.getAbrechnungMonatAdmin(row[0].Jahr, row[0].Monat)
        if (r.data.length > 0) {
          this.currRow = row[0]
          for (let i = 0; i < r.data.length; i += 1) r.data[i]._showDetails = false // eslint-disable-line
          this.itemsMonat = r.data
          this.totalRows = this.itemsMonat.length
        }
        row[0]._showDetails = !row[0]._showDetails  // eslint-disable-line
      } else this.currRow._showDetails = !this.currRow._showDetails  // eslint-disable-line
    },
    showAbrech(row) {
      if (row.length === 1) this.currRowAbrech = row[0]
      this.currRowAbrech._showDetails = !this.currRowAbrech._showDetails  // eslint-disable-line
    },
    async generateAll(item) {
      if (item.Status === 'erstellen') {
        // const r = await db.generateAbrechnung(item.MandantID, this.currRow.Jahr, this.currRow.Monat)
        // if (this.currRowAbrech.AbrechnungID === 0) this.currRowAbrech.AbrechnungID = r.data[0]
      }
    },
    async create() {
      if (this.currRowAbrech.Status === null) {
        const r = await dba.createAbrechnung(this.currRowAbrech.MandantID, this.currRow.Jahr, this.currRow.Monat)
        if (this.currRowAbrech.AbrechnungID === null) this.currRowAbrech.AbrechnungID = r.data
      }
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Änderungen gespeichert',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Abrechnung wurde erfolgreich erstellt!',
          },
        },
        { position: 'bottom-right' },
      )
    },
    async send() {
      const r = await dba.AbrechnungSendMail(this.currRowAbrech.AbrechnungID, 'abrechnung') // eslint-disable-line
      this.currRowAbrech.Status = 'versand'
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Änderungen gespeichert',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Abrechnung wurde erfolgreich versandt!',
          },
        },
        { position: 'bottom-right' },
      )
    },
    async download() {
      const r = await dba.getAbrechnungDatei(this.currRowAbrech.AbrechnungID, 'abrechnung')
      const fileURL = window.URL.createObjectURL(r.data)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', 'Abrechnung.pdf')
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    async speichern() {
      const r = await dba.getAbrechnungUpdate(this.currRowAbrech.AbrechnungID, this.currRowAbrech.Status) // eslint-disable-line
      if (this.anzahlBelegeTotal === 0) this.anzahlBelegeTotal = 1
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Änderungen gespeichert',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Abrechungsdaten wurden erfolgreich gespeichert!',
          },
        },
        { position: 'bottom-right' },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
